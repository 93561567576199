@import "../../../../assets/scss/variables";

#friends {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  background-color: #fff;
  margin-bottom: 1rem;

  @media (min-width: 800px) {
    flex-grow: 1;
    margin-right: 2rem;
    max-width: 300px;
    min-width: 280px;
    margin-bottom: 0;
  }

  #title {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    /*align-items: flex-end;*/


    button {
      border: none;
      background-color: #9C0757;
      color: white;
      width: 60px;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 600;
    }

    h1 {
      font-size: 20px;
      font-weight: 900;
      color: #5c5c5c;
    }

  }

  hr {
    border-top: 1px solid #cfcfcf;
    width: 100%;
    margin-bottom: 0
  }

  #friends-box {
    overflow-y: auto;
    display: flex;
    text-align: left;

    @media (min-width: 800px) {
      flex-direction: column;
    }
  }

  #no-chat {
    padding: 10px;
  }

  .opened {
    background-color: #ececec;
  }

  #suggestions {

    margin-top: 10px;
    overflow-y: auto;
    max-height: 300px;

    .suggestion {
      margin: 10px 0;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: $success;
        border-radius: 5px;
        border: none;
        color: white;
        cursor: pointer;
        padding: 5px;
        font-size: 15px;
      }
    }
  }
}