.friend-list:hover {
  background-color: #fff2f9;
}

.friend-list {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  flex-direction: column;
  min-width: fit-content;


  @media (min-width: 800px) {
    flex-direction: row;
  }

  & > div {
    display: flex;

    img {
      display: flex;
      align-self: center;
      margin-right: 5px;
      border-radius: 50%;
    }

    .friend-info {
      display: flex;
      align-self: center;
      flex-direction: column;

      h5 {
        font-size: 12px;
        color: #686868;
        margin-top: 2px;
      }
    }
  }
  .friend-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

