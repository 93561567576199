@import '../../../../assets/scss/variables';

#chatter {
  overflow-y: auto;
  display: flex;
  text-align: left;
  margin: 5px;

  h3 {
    margin: 0 10px 0 0;
    font-size: 15px;
    font-weight: 600;
  }

  .chatter-info {
    display: flex;
    margin-right: 10px;
    min-width: 80px;
    padding: 10px;
    border-radius: 5px;
    background-color: #ececec;
  }

  .chatter-status {
    display: flex;
    align-items: center;
  }
}