@import '../../assets/scss/variables';

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;

    .modal-wrapper {
        margin: 2rem 10px 10px 10px;

        .modal-container {
            max-width: 400px;
            margin: 0px auto;
            border-radius: 2px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
            transition: all 0.3s ease;
            font-family: Helvetica, Arial, sans-serif;

            .modal-header {
                background-color: $bg-main;
                padding: 10px;
                color: white;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                text-align: center;
            }

            .modal-body {
                padding: 20px;
                background-color: white;
                text-align: left;

                input {
                    background-color: #f5f6f8;
                    border: none;
                    padding: 10px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            .modal-footer {
                padding: 10px;
                background-color: white;
                display: flex;
                justify-content: space-between;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                button {
                    background-color: $bg-main;
                    border: none;
                    padding: 5px;
                    width: 70px;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                    font-weight: 600;
                }
            }
        }
    }
}