@import '../../assets/scss/_variables.scss';

#auth-container {
  min-height: 100vh;
  background-color: $bg-main;

  #auth-card {
    display: flex;
    justify-content: center;
    position: relative;
    top: 90px;

    & > div {
      display: flex;
      flex-direction: column;
      min-width: 100%;
      margin-top: 10px;
      background-color: white;
      border-radius: 20px;

      @media (min-width: 800px) {
        flex-direction: row;
        min-width: 500px;
        margin: 7.5rem;
      }

      #image-section {
        background-color: $bg-light;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;

        @media (min-width: 800px) {
          border-top-right-radius: 0;
          border-bottom-left-radius: 20px;
        }

        img {
          max-height: 500px;
          max-width: 100%;
        }
      }

      #form-section {
        min-width: 100%;

        @media (min-width: 800px) {
          min-width: 300px;

        }

        h2 {
          text-align: center;
          margin: 25px 35px;
          font-size: 25px;
          font-weight: 900;
        }

        p {
          font-size: 13px;
          padding: 2px;
        }

        form {

          padding: 20px;

          .input-field {

            input,
            select {
              height: 45px;
              width: 100%;
              box-sizing: border-box;
              padding: 1px;
              font-size: 15px;
            }

          }

          button {
            width: 100%;
            height: 30px;
            background-color: $bg-main;
            border: none;
            color: white;
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    }
  }
}