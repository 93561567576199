@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.App {
    text-align: center;
}

.card-shadow {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(64, 64, 64, 0.5);
    -moz-box-shadow: 0px 5px 5px 0px rgba(64, 64, 64, 0.5);
    box-shadow: 0px 5px 5px 0px rgba(64, 64, 64, 0.5);
}

.shadow-light {
    -webkit-box-shadow: 0px 3px 5px 0px rgba(122, 122, 122, 0.2);
    -moz-box-shadow: 0px 3px 5px 0px rgba(122, 122, 122, 0.2);
    box-shadow: 0px 3px 5px 0px rgba(122, 122, 122, 0.2);
}

.m-0 {
    margin: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}